.App {
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cf4036;
  border-radius: 10px;
  cursor: "pointer";
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: red;
  cursor: "pointer";
}

body {
  background-color: #000;
  background-image: url("./assets/images/bg-dd-white.png");
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* backdrop-filter: brightness(80%) blur(1px); */
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}

body::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.9);  */
}

.match-card {
  width: 400px;
  height: 265;
}

.card-list {
  margin-left: 10px;
  margin-top: 20px;
}

.card-list-list {
  width: 100%;
  max-height: 100%;
  overflow: auto;
}

.card-list-faq {
  width: 100%;
  max-height: 550px;
  overflow: auto;
}

.completed-leaderboard {
  border: #fff;
  background-color: #fff;
  border-radius: 20px;
  max-width: 500px;
}

.score-label {
  color: #ffc003;
  -webkit-text-stroke: 1px black;
  font-size: 2em;
  font-weight: bolder;
  text-shadow: 20px 20px 20px rgb(54, 56, 41);
}

.score-points {
  color: #ffc003;
  -webkit-text-stroke: 1px black;
  font-size: larger;
  font-weight: bolder;
  font-size: 3em;
  text-shadow: 20px 20px 20px rgb(54, 56, 41);
}

.fullscreen-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
}

.home-promo-banner {
  background-color: white;
  margin-inline: 350px;
  padding: 12px;
  border-radius: 20px;
}


.fullscreen-overlay.visible {
  display: flex;
}

@media (max-height: 1400px) {
  .card-list-list {
    height: 100%;
  }

  .award-animation {
    height: 220px;
  }


}

@media (max-height: 1200px) {
  .logo-img {
    margin-top: 40px !important;
  }

  .card-list-list {
    height: 100%;
  }


}

@media (max-height: 700px) {
  .logo-img {
    margin-top: 5px !important;
    width: 150px !important;
  }
  .match-card {
    width: 310px;
  }

  .award-animation {
    margin-top: 5px !important;
    height: 130px !important;
  }

  .home-promo-banner {
    margin-inline: 1px
}
}

@media (max-height: 900px) {
  .card-list {
    margin-left: 40px;
  }

  .match-card {
    width: 315px;
  }

  .award-animation {
    height: 200px;
  }

  .home-promo-banner {
    margin-inline: 1px
}
}

@media (max-height: 620px) {
  .card-list-list {
    max-height: 100%;
  }

  .award-animation {
    height: 130px;
  }
}

.aBtn {
  background-color: aliceblue;
  width: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.input-ball {
  height: 50px;
  width: 55px;
  margin-top: 10px;
}

.answer-ball {
  height: 50px;
  width: 55px;
  margin-top: 5px;
  margin-right: 50px;
}

.q-answer-ball {
  height: 50px;
  width: 55px;
  margin-top: 5px;
  margin-left: 70px;
}

.input-field {
  background-color: aliceblue;
  border-radius: 25px;
  border-style: none;
  height: 50px;
  box-shadow: 10px 10px 20px rgb(56, 41, 41);
}

.input-field input {
  padding-top: 12px;
  padding-left: 10px;
  font-size: 1.1em;
}

.input-field::before {
  background-color: rgb(255, 240, 240);
  outline: none;
}

.next-btn {
  width: 270px;
  border-style: none;
  margin-top: 5% !important;
  border-radius: 100px !important;
}

.validation-msg {
  color: #cf4036;
  font-style: italic;
  font-weight: bold;
  font-size: 0.8em;
  margin-top: 20px;
}

.points {
  display: flex;
  justify-content: space-between;
}

.time-ball {
  width: "100%";
  overflow: hidden;
}

.question {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: "2em";
}

.animation-modal {
  background: rgba(0, 0, 0, 0.5);
}

.ball-player {
  display: absolute;
  z-index: -1;
}

.match-animation {
  height: 400px;
}

.match-card-content {
  background-image: linear-gradient(#cf4036, #bd1307);
  color: aliceblue;
  padding: 0;
  margin: 0;
  /* border-top-right-radius: 50%;
  border-top-left-radius: 50%; */
}

.leaderboard-modal {
  background-color: white;
  color: #192130;
}

@media (max-width: 550px) {
  .card-list {
    margin-left: 60px;
  }
}

@media (max-width: 450px) {
  .card-list {
    margin-left: 32px;
  }
}

@media (max-width: 400px) {
  .card-list {
    margin-left: 20px;
  }
}

@media (max-width: 370px) {
  .card-list {
    margin-left: 10px;
  }
}

@media (max-width: 600px) {
  body {
    background-image: url("./assets/images/bg-mmm-white.png");
    background-repeat: repeat;
  }

  /* .card-list {
    margin-left: 20px;
  } */

  .next-btn {
    margin-top: 15% !important;
  }

  .margin-issue {
    margin-right: 100px;
  }

  .match-animation {
    height: 300px;
  }
}

.logo-img {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  margin-bottom: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  /* 
  .home-img {
    width: 100px;
  } */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-styles {
  position: absolute;
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
  box-shadow: 24;
  padding: 4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
